import React from "react"

import {graphql, Link} from 'gatsby'

import {getPageUrl} from '../lib/helpers'

import Layout from '../containers/layout'

import BlogPostPreview from '../components/blog-post-preview'
import Container from '../components/container'
import SearchBar from '../components/search-bar'
import MetaSchemaSEO from '../components/seo';


import * as styles from './latest_template.module.css'

export const query = graphql`
  query recentlyUpdatedPagesDynamic($startN:Int, $limitN:Int){
    allStrapiPage(
      sort: {fields: updatedAt, order: DESC}
      limit: $limitN
      skip: $startN
    ) {
      nodes {
        id
        slug
        is_featured
        page_type {
          name
          slug_prefix
        }
        site_section: page_type{
          name
          slug_prefix
        }
        title
        excerpt
        publishedAt
        parent {
          id
        }
        main_image{
          url
          alternativeText
          caption
          localFile{
            childImageSharp{
              gatsbyImageData(
                width: 150,
                aspectRatio: 1,
                transformOptions: {cropFocus:ENTROPY}
              )
            }
          }
        }
      }
    }
  }
`

// markup
const ShowPost = ({post, key}) => {

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  if ( (getRandomInt(7) == 1 && post.main_image) || post.is_featured ) {
    return (
      <div key={post.id}>
        <ul className={styles.grid}>
          <li>
            <BlogPostPreview {...post} isInList={true} />
          </li>
        </ul>
      </div>
    )
  } else {
    return (
      <li key={post.id} className={styles.li}>
        <Link to={getPageUrl(post)}>{post.title}</Link>
      </li>
    )
  }
}
const LatestPages = props => {
  const {pageContext, data} = props
  const {messageContent} = pageContext
  const postNodes = data.allStrapiPage.nodes


  return (
    <Layout>
      <MetaSchemaSEO
      />
      <Container>
        { process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
      </Container>
      <Container>
        <h1>Latest Pages</h1>
        <div>{messageContent}</div>
            {postNodes.map(post => (
                <ShowPost post={post} key={post.id}></ShowPost>
            ))}
        { process.env.CONTENTCURATOR_SITESEARCH_INDEXNAME && process.env.CONTENTCURATOR_SITESEARCH_CREDENTIALS && 
          <SearchBar></SearchBar>
        }
        { postNodes.length > pageContext.limit && pageContext.page_num + 1 < pageContext.max_page_num &&
        <div className={styles.morePages}>
          <Link to={'/page/'+String(pageContext.page_num + 1)}>More recently published pages</Link>
        </div>
        }
      </Container>
    </Layout>
  )
}

export default LatestPages
